import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from "@angular/animations";
import { NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap";
//declare var $: any;
//declare var $: any;
@Component({
  selector: "app-home-slider",
  templateUrl: "./home-slider.component.html",
  styles: [``],
  animations: []
})
export class HomeSliderComponent implements OnInit {
  constructor() {}
  sliderImages = [
    "/assets/img/slider/1.jpg",
    "/assets/img/slider/2.jpg",
    "/assets/img/slider/3.jpg",
  ];
  ngOnInit() {}

  active_id = "0";
  onSlide(slideEvent: NgbSlideEvent) {
    this.active_id = slideEvent.current.slice(-1);
  }
}

<div class="modal-header">
  <h4 class="modal-title">
    {{ info.data[2] | number: "2.0" }}/ {{ info.data[1] | number: "2.0" }} -
    {{ info.titulo }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <img
    [src]="info.foto ? '' + info.foto : '/assets/img/sem-imagem.png'"
    [alt]="info.titulo"
    class="w-50 float-left rounded mr-3"
  />
  <p [innerHTML]="conteudo"></p>
</div>

export function filetypeIcone(filetype) {
  if (!filetype) {
    return "";
  }

  if (filetype.includes("image")) {
    return "ion-image";
  }
  if (filetype.includes("audio")) {
    return "ion-volume-medium";
  }
  if (filetype.includes("video")) {
    return "ion-ios-movie";
  }

  if (filetype.includes("html") || filetype.includes("json")) {
    return "ion-code";
  }

  if (filetype.includes("zip")) {
    return "ion-folder";
  }

  return "ion-document";
}

import { ApiService } from "src/app/_services/api.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-lista-escolas",
  templateUrl: "./lista-escolas.component.html",
  styleUrls: ["./lista-escolas.component.css"],
})
export class ListaEscolasComponent implements OnInit {
  filtro_escolas: "Creche" | "CrecheInfantil" | "Infantil" | "InfantilFundamental" | "Fundamental" | "CEMAD";
  constructor(public api: ApiService) {
    this.filtro_escolas = "Creche";
  }

  ngOnInit() {}
}

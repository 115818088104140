import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  constructor(@Inject(DOCUMENT) document) {}

  @HostListener("window:scroll", ["$event"])
  handleScroll(e) {
    if (window.pageYOffset > 250) {
      let element = document.getElementById("header");
      element.classList.add("header-scrolled");
    } else {
      let element = document.getElementById("header");
      element.classList.remove("header-scrolled");
    }
    if (window.pageYOffset > 450) {
      let element = document.getElementById("back-to-top");
      element.style.display = "block";
    } else {
      let element = document.getElementById("back-to-top");
      element.style.display = "none";
    }
  }

  ngOnInit() {
    // // Initiate superfish on nav menu
    // $(".nav-menu").superfish({
    //   animation: {
    //     opacity: "show"
    //   },
    //   speed: 400
    // });

    // // Mobile Navigation
    // if ($("#nav-menu-container").length) {
    //   var $mobile_nav = $("#nav-menu-container")
    //     .clone()
    //     .prop({
    //       id: "mobile-nav"
    //     });
    //   $mobile_nav.find("> ul").attr({
    //     class: "",
    //     id: ""
    //   });
    //   $("body").append($mobile_nav);
    //   $("body").prepend(
    //     '<button type="button" id="mobile-nav-toggle"><i class="ion-android-menu"></i></button>'
    //   );
    //   $("body").append('<div id="mobile-body-overly"></div>');
    //   $("#mobile-nav")
    //     .find(".menu-has-children")
    //     .prepend('<i class="ion-android-arrow-dropdown"></i>');

    //   $(document).on("click", ".menu-has-children i", function(e) {
    //     $(this)
    //       .next()
    //       .toggleClass("menu-item-active");
    //     $(this)
    //       .nextAll("ul")
    //       .eq(0)
    //       .slideToggle();
    //     $(this).toggleClass(
    //       "ion-android-arrow-dropup ion-android-arrow-dropdown"
    //     );
    //   });

    //   $(document).on("click", "#mobile-nav-toggle", function(e) {
    //     $("body").toggleClass("mobile-nav-active");
    //     $("#mobile-nav-toggle i").toggleClass("ion-close ion-android-menu");
    //     $("#mobile-body-overly").toggle();
    //   });

    //   $(document).click(function(e) {
    //     var container = $("#mobile-nav, #mobile-nav-toggle");
    //     if (!container.is(e.target) && container.has(e.target).length === 0) {
    //       if ($("body").hasClass("mobile-nav-active")) {
    //         $("body").removeClass("mobile-nav-active");
    //         $("#mobile-nav-toggle i").toggleClass("ion-close ion-android-menu");
    //         $("#mobile-body-overly").fadeOut();
    //       }
    //     }
    //   });
    // } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
    //   $("#mobile-nav, #mobile-nav-toggle").hide();
    // }

    // // Smooth scroll for the menu and links with .scrollto classes
    // $(".nav-menu a, #mobile-nav a, .scrollto").on("click", function() {
    //   if (
    //     location.pathname.replace(/^\//, "") ==
    //       this.pathname.replace(/^\//, "") &&
    //     location.hostname == this.hostname
    //   ) {
    //     var target = $(this.hash);
    //     if (target.length) {
    //       var top_space = 0;

    //       if ($("#header").length) {
    //         top_space = $("#header").outerHeight();

    //         if (!$("#header").hasClass("header-scrolled")) {
    //           top_space = top_space - 20;
    //         }
    //       }

    //       $("html, body").animate(
    //         {
    //           scrollTop: target.offset().top - top_space
    //         },
    //         1500,
    //         "easeInOutExpo"
    //       );

    //       if ($(this).parents(".nav-menu").length) {
    //         $(".nav-menu .menu-active").removeClass("menu-active");
    //         $(this)
    //           .closest("li")
    //           .addClass("menu-active");
    //       }

    //       if ($("body").hasClass("mobile-nav-active")) {
    //         $("body").removeClass("mobile-nav-active");
    //         $("#mobile-nav-toggle i").toggleClass("ion-close ion-android-menu");
    //         $("#mobile-body-overly").fadeOut();
    //       }
    //       return false;
    //     }
    //   }
    // });
  }
}

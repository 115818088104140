<section id="topo_politica" class="wow fadeIn">
  <div class="container">
    <header class="section-header">
      <h3>Política de Privacidade</h3>
    </header>
  </div>
</section>

<div class="container mt-5" style="min-height: 300px">
  <h3 class="text-center font-weight-bold">
    Política de Privacidade Aplicativo EducaSim
  </h3>
  <!--==========SEÇÃO 1==========-->
  <h6 class="mt-5 font-weight-bold">SEÇÃO 1 - INFORMAÇÕES GERAIS</h6>
  <p class="text-justify">
    A presente Política de Privacidade contém informações sobre coleta, uso,
    armazenamento, tratamento e proteção dos dados pessoais dos usuários e
    visitantes do aplicativo EducaSim - Portal da Comunidade de Mairiporã, com a
    finalidade de demonstrar absoluta transparência quanto ao assunto e
    esclarecer a todos interessados sobre os tipos de dados que são coletados,
    os motivos da coleta e a forma como os usuários podem gerenciar ou excluir
    as suas informações pessoais. <br /><br />
    Esta Política de Privacidade aplica-se a todos os usuários e visitantes do
    aplicativo EducaSim - Portal da Comunidade de Mairiporã e foi foi elaborada
    em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei
    13.709/18), o Marco Civil da Internet (Lei 12.965/14) e o Regulamento da UE
    (n. 2016/6790). Ainda, o documento poderá ser atualizado em decorrência de
    eventual atualização normativa, razão pela qual se convida o usuário a
    consultar periodicamente esta seção.
  </p>
  <!--==========SEÇÃO 2 ==========-->
  <h6 class="mt-5 font-weight-bold">
    SEÇÃO 2 - DADOS SOLICITADOS E SUA FINALIDADE
  </h6>
  <p class="text-justify">
    Os dados pessoais solicitados e fornecidos pelos usuários por meio do
    aplicativo se relacionam às seguintes finalidades:
  </p>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">DADO SOLICITADO</th>
          <th scope="col">FINALIDADE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Identificadores do dispositivo</td>
          <td>
            IDs de instalação do Firebase para o envio de Push Notification
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--==========SEÇÃO 3==========-->
  <h6 class="mt-5 font-weight-bold">
    SEÇÃO 3 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
  </h6>
  <p class="text-justify">
    Os dados pessoais do usuário e visitante são armazenados pela plataforma
    durante o período necessário para a prestação do serviço ou o cumprimento
    das finalidades previstas no presente documento, conforme o disposto no
    inciso I do artigo 15 da Lei 13.709/18.
    <br /><br />
    Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando
    os casos em que a lei oferecer outro tratamento.
    <br /><br />
    Ainda, os dados pessoais dos usuários apenas podem ser conservados após o
    término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da
    referida lei:
  </p>

  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      I - cumprimento de obrigação legal ou regulatória pelo controlador;
    </li>
    <li class="list-group-item">
      II - estudo por órgão de pesquisa, garantida, sempre que possível, a
      anonimização dos dados pessoais;
    </li>
    <li class="list-group-item">
      III - transferência a terceiro, desde que respeitados os requisitos de
      tratamento de dados dispostos nesta Lei;
    </li>
    <li class="list-group-item">
      IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde
      que anonimizados os dados.
    </li>
  </ul>
  <!--========== SEÇÃO 4==========-->
  <h6 class="mt-5 font-weight-bold">
    SEÇÃO 4 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
  </h6>
  <p class="text-justify">
    A plataforma se compromete a aplicar as medidas técnicas e organizativas
    aptas a proteger os dados pessoais de acessos não autorizados e de situações
    de destruição, perda, alteração, comunicação ou difusão de tais dados.
    <br /><br />
    A plataforma não se exime de responsabilidade por culpa exclusiva de
    terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva
    do usuário, como no caso em que ele mesmo transfere seus dados a terceiros.
    O site se compromete a comunicar o usuário em caso de alguma violação de
    segurança dos seus dados pessoais.
    <br /><br />
    Os dados pessoais armazenados são tratados com confidencialidade, dentro dos
    limites legais. No entanto, podemos divulgar suas informações pessoais caso
    sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de
    Serviço.
  </p>
  <!--========== SEÇÃO 5==========-->
  <h6 class="mt-5 font-weight-bold">SEÇÃO 5 -CONSENTIMENTO</h6>
  <p class="text-justify">
    Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o
    usuário está consentindo com a presente Política de Privacidade.<br /><br />
    <!-- O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus
    direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e
    garante a veracidade das informações por ele disponibilizadas.<br /><br /> -->
    O usuário tem direito de retirar o seu consentimento a qualquer tempo, para
    tanto deve entrar em contato através do e-mail
    <b>admin@multfacilcomercial.com.br</b>
  </p>
  <!--========== SEÇÃO 6==========-->
  <h6 class="mt-5 font-weight-bold">
    SEÇÃO 6 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
  </h6>
  <p class="text-justify">
    Reservamos o direito de modificar essa Política de Privacidade a qualquer
    momento, então, é recomendável que o usuário e visitante revise-a com
    frequência. <br /><br />
    As alterações e esclarecimentos vão surtir efeito imediatamente após sua
    publicação na plataforma.
    <!-- Quando realizadas alterações os usuários serão
    notificados.  -->
    Ao utilizar o serviço ou fornecer informações pessoais após eventuais
    modificações, o usuário e visitante demonstra sua concordância com as novas
    normas.<br /><br />
    Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários
    podem ser transferidas para os novos proprietários para que a permanência
    dos serviços oferecidos.
  </p>
  <!--========== SEÇÃO 7==========-->
  <h6 class="mt-5 font-weight-bold">
    SEÇÃO 7 - JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS
  </h6>
  <p>
    Para a solução de controvérsias decorrentes do presente instrumento será
    aplicado integralmente o Direito brasileiro.<br /><br />
    Os eventuais litígios deverão ser apresentados no foro da comarca em que se
    encontra a sede da empresa.
  </p>
  <!--========== SEÇÃO 8==========-->
  <div class="mt-5 mb-5">
    MULTFACIL COMERCIAL EIRELI <br /><br />
    <b>Contato: </b>admin@multfacilcomercial.com.br <br /><br />
    <b>Endereço: </b>Rua Itajaí, nº 80, 10º Andar Alpha Office Place Jardim
    Aquarius - São José dos Campos/SP
  </div>
</div>

<section id="topo_noticias" class="wow fadeIn">
  <div class="container">
    <header class="section-header">
      <h3>Notícias</h3>
    </header>
  </div>
</section>
<!--=====Notícias=====-->
<div class="container mt-5" style="min-height: 300px">
  <app-spinner *ngIf="!noticia"></app-spinner>
  <ng-container *ngIf="noticia">
    <h3 class="text-center font-weight-bold">{{ noticia.titulo }}</h3>
    <h4 class="text-center">{{ noticia.subtitulo }}</h4>

    <div class="text-justify mt-5 mb-5">
      <ckeditor
        [editor]="Editor"
        [config]="{
          language: 'pt-br',
          toolbar: []
        }"
        [data]="noticia.conteudo"
        [disabled]="true"
      ></ckeditor>
    </div>

    <!--=====ANEXOS=====-->
    <div class="mt-5 mb-5" *ngIf="noticia.anexos && noticia.anexos.length > 0">
      <h5>Anexos</h5>
      <app-lista-anexos
        [anexos]="noticia.anexos"
        [url_anexo]="'portal-comunidade/noticias/downloadanexo/'"
        direto="true"
      ></app-lista-anexos>
    </div>

    <!--=====Carrossel=====-->
    <div class="text-center mt-5 mb-5" *ngIf="noticia.fotos">
      <ngb-carousel>
        <ng-template ngbSlide *ngFor="let foto of noticia.fotos">
          <div class="picsum-img-wrapper">
            <img
              [src]="foto.file"
              alt="imagem"
              class="img-fluid"
              style="height: 500px; object-fit: contain"
            />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </ng-container>
</div>

<section id="topo_eventos" class="wow fadeIn">
  <div class="container">
    <header class="section-header">
      <h3>Eventos</h3>
    </header>
  </div>
</section>

<div class="container mt-5" style="min-height: 300px">
  <app-spinner *ngIf="!evento"></app-spinner>
  <ng-container *ngIf="evento">
    <h3 class="text-center font-weight-bold">{{ evento.titulo }}</h3>
    <h4 class="text-center">{{ evento.subtitulo }}</h4>

    <div class="text-justify mt-5 mb-5">
      <ckeditor
        [editor]="Editor"
        [config]="{
          language: 'pt-br',
          toolbar: []
        }"
        [data]="evento.descricao"
        [disabled]="true"
      ></ckeditor>
    </div>

    <h6>
      <strong>Endereço:</strong>
      {{ evento.endereco.logradouro }} , Nº {{ evento.endereco.numero }} -
      {{ evento.endereco.bairro }}
      <br />
      <br />
      <strong>Data de Início:</strong>
      {{ evento.dataInicio | date: "short" }}
      <br /><br />
      <strong>Data Final:</strong>
      {{ evento.dataTermino | date: "short" }}

      <ng-container *ngIf="evento.endereco.telefone">
        <br />
        <br />
        <strong>Telefone:</strong>
        {{ evento.endereco.telefone }}
      </ng-container>
    </h6>

    <!--=====ANEXOS=====-->
    <div class="mt-5 mb-5" *ngIf="evento.docAnexo">
      <h5>Download Anexo</h5>
      <app-lista-anexos
        [anexos]="[evento.docAnexo]"
        [url_anexo]="'portal-comunidade/eventos/downloadanexo/'"
        direto="true"
      ></app-lista-anexos>
    </div>

    <!--=====Carrossel=====-->
    <div class="text-center mt-5 mb-5" *ngIf="evento.fotosEventos">
      <ngb-carousel>
        <ng-template ngbSlide *ngFor="let f of evento.fotosEventos">
          <div class="picsum-img-wrapper">
            <img
              [src]="f.foto"
              alt="imagem"
              class="img-fluid"
              style="height: 500px; object-fit: contain"
            />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </ng-container>
</div>

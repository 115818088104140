import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject, of } from "rxjs";
import { Colaborador } from "../interfaces/colaborador.model";
import { Escola } from "../interfaces/escola.model";
import { RespostaApi } from "../interfaces/resposta.model";
import { Noticia } from "../interfaces/noticia.model";
import { tap, debounceTime, switchMap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";

const API_URL = environment.api;
//const mapaApiKey = "AIzaSyA-d4Z-jrNra0SWFxgzbzPITkd9TCFcpK8";
const mapaApiKey = "AIzaSyBa1o-EUd-G-YHify9aGm37sWGps_es86I";
interface SearchResult {
  colaboradores: Colaborador[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  filtro_escola: string;
}

function matches(colaborador: Colaborador, term: string) {
  return (
    colaborador.nome.toLowerCase().includes(term.toLowerCase()) ||
    colaborador.escola.toLowerCase().includes(term.toLowerCase())
  );
}

@Injectable({
  providedIn: "root"
})
export class ApiService {
  mock: RespostaApi = {
    escolasDto: [
      {
        id: 7,
        foto: "/assets/img/escolas/EM NICOLAU PINTO DA SILVA.jpg",
        nome: "EM NICOLAU PINTO DA SILVA",
        localizacao: "Estrada Armando B. de Almeida, 30 - Nucleo Residencial",
        telefone: "(11) 4419-5659",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 8,
        foto: "/assets/img/escolas/CRECHE ANADIR VICENTINA SILVERIO PUGA.jpg",
        nome: "CRECHE ANADIR VICENTINA SILVERIO PUGA",
        localizacao: "Rua Anjo Gabriel, 57 - Canjica - Terra Preta",
        telefone: "(11) 3777-9350",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 9,
        foto: "/assets/img/escolas/CRECHE ERMELINA RAMPINI DA SILVA.jpg",
        nome: "CRECHE ERMELINDA RAMPINI DA SILVA",
        localizacao: "Rua Ideni Tampelini Santos, 15 - Colinas I - Terra Preta",
        telefone: "(11) 4486-2223",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 10,
        foto: "/assets/img/escolas/CRECHE KATSUKO SHIMURA.jpg",
        nome: "CRECHE KATSUKO SHIMURA",
        localizacao: "Rua Laudemiro Ramos, 65 - Centro",
        telefone: "(11) 4604-5236",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 11,
        foto: "/assets/img/escolas/CRECHE LUIZ ANTONIO PEREIRA.jpg",
        nome: "CRECHE LUIZ ANTONIO PEREIRA",
        localizacao:
          "Rua Francisco Miranda de Melo,130 - Jardim Pereira - Terra Preta",
        telefone: "(11) 4486-3850",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 12,
        foto: "/assets/img/escolas/CRECHE MARIA DA GLÓRIA PETRANSAN.jpg",
        nome: "CRECHE MARIA DA GLÓRIA PETRANSAN",
        localizacao: "Rua Felipe Salomão Chamma, 165 - Chácara Arantes",
        telefone: "(11) 4419-2766",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 13,
        foto: "/assets/img/escolas/CRECHE MARIA TEREZINHA.jpg",
        nome: "CRECHE MARIA TEREZINHA ROCHA CHAMMA",
        localizacao:
          "Rua Benedito Galrão de França, 38, 07600-000, Mairiporã - SP",
        telefone: "(11) 4604 - 6812",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 14,
        foto: "/assets/img/escolas/CRECHE NAIR MONTEIRO ARNONI.jpg",
        nome: "CRECHE NAIR MONTEIRO ARNONI",
        localizacao: "Rua Canuto de Pito, s/n - Parque Petropolis",
        telefone: "(11) 4485-3999/4485-9063",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 15,
        foto:
          "/assets/img/escolas/CRECHE SEBASTIÃO FRANCISCO DA COSTA - TIO BASTECO.jpg",
        nome: "CRECHE SEBASTIÃO FRANCISCO DA COSTA - TIO BASTECO",
        localizacao: "Rua Primavera s/nº - Parque Nautico",
        telefone: "(11) 4419-7214",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 16,
        foto: "/assets/img/escolas/CRECHE SILVIA MARIA PIUNTI GARCIA.jpg",
        nome: "CRECHE SILVIA MARIA PIUNTI GARCIA",
        localizacao: "Rua Olavo Bilac, 95 - Centro",
        telefone: "(11) 4419-1753/97541-0358",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 17,
        foto: "/assets/img/escolas/CRECHE SUMICO MAEDA YANASE.jpg",
        nome: "CRECHE SUMICO MAEDA YANASE",
        localizacao: "Rua Fernando Spada, 123 - Jardim Spada",
        telefone: "(11) 4604-3815",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 18,
        foto: "/assets/img/escolas/EM ELIZA NEGRI DA SILVA.jpg",
        nome: "EM ELIZA NEGRI DA SILVA",
        localizacao: "Rua Ascano, 211 - Parque Suiço",
        telefone: "(11) 4484-4900",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 19,
        foto: "/assets/img/escolas/EM GUIDO PISANESCHI.jpg",
        nome: "EM GUIDO PISANESCHI",
        localizacao: "Al. dos Sibipirunas, 2 - Jardim Suisso",
        telefone: "(11) 4419-1726",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 20,
        foto: "/assets/img/escolas/EM HIPÓLITO FERRARI.jpg",
        nome: "EM HIPÓLITO FERRARI",
        localizacao: "Estr. Norberto Antonio de Moraes, S/nº - Pirucaia",
        telefone: "(11) 4483-4700",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 21,
        foto: "/assets/img/escolas/EM IDALINA DA SILVA CARDOSO.jpg",
        nome: "EM IDALINA DA SILVA CARDOSO",
        localizacao: "Av. José A. de Moraes Neto, s/nº - Palmeiras",
        telefone: "(11) 4485-3855",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 22,
        foto: "/assets/img/escolas/EM INÁCIO PEREIRA DE OLIVEIRA FILHO.jpg",
        nome: "EM INÁCIO PEREIRA DE OLIVEIRA FILHO",
        localizacao: "Estrada do Rio Acima, Km 14 - Rio Acima",
        telefone: "(11) 4419-2717",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 23,
        foto: "/assets/img/escolas/EM JOÃO PUGA DIAS.jpg",
        nome: "EM JOÃO PUGA DIAS",
        localizacao: "Rua dos Cravos, 777 - Bela Vista - Terra Preta",
        telefone: "(11) 4486-2228",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 24,
        foto: "/assets/img/escolas/EM JOÃO VICENTE DE ARAUJO FILHO.jpg",
        nome: "EM JOÃO VICENTE DE ARAUJO FILHO",
        localizacao: "Estrada Municipal do Saboó, s/nº - Saboó",
        telefone: "(11) 4483-4392/ 99135-1486",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 25,
        foto: "/assets/img/escolas/EM JOSÉ ARNONI.jpg",
        nome: "EM JOSÉ ARNONI",
        localizacao: "Estrada Municipal Do Rio Acima, km 09 - Vila Arnoni",
        telefone: "(11) 4419-1804",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 26,
        foto: "/assets/img/escolas/EM JOSÉ DA SILVEIRA PINHEIRO.jpg",
        nome: "EM JOSÉ DA SILVEIRA PINHEIRO",
        localizacao: "Rua Fernão Lopes, 30 - Jardim Ester",
        telefone: "(11) 4419-2888",
        gestao: "",
        tipo: "CrecheInfantil"
      },
      {
        id: 27,
        foto: "/assets/img/escolas/EM LUIZ TELES BATAGINI.jpg",
        nome: "EM LUIZ TELES BATAGINI",
        localizacao: "Rua Ipiranga, 62A - Centro",
        telefone: "(11) 4419-4188",
        gestao: "",
        tipo: "Infantil"
      },
      {
        id: 28,
        foto: "/assets/img/escolas/EM MÁRCIA MONTEIRO PEREIRA.jpg",
        nome: "EM MÁRCIA MONTEIRO PEREIRA",
        localizacao: "Rua Ver. José Gonçalves, 245 - Vila Sabesp",
        telefone: "(11) 4419-5888",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 29,
        foto: "/assets/img/escolas/EM MARILHA DE OLIVEIRA PINTO.jpg",
        nome: "EM MARILHA OLIVEIRA PINTO",
        localizacao: "Estrada Municipal Henrique Ortiz, s/nº - Bairro Itaim",
        telefone: "(11) 4486-5155",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 30,
        foto: "/assets/img/escolas/EM MOACYR ARCHANJO DOS SANTOS.jpg",
        nome: "EM MOACYR ARCHANJO DOS SANTOS",
        localizacao: "Estrada Santa Inês, 3000 - Jardim Samambaia",
        telefone: "(11) 4485-4799",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 31,
        foto: "/assets/img/escolas/EM MUFARREGE SALOMÃO CHAMA.jpg",
        nome: "EM MUFARREGE SALOMÃO CHAMA",
        localizacao: "Rua Celso Epaminondas s/nº - Lavapés",
        telefone: "(11) 4419-1729",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 32,
        foto: "/assets/img/escolas/EM NAKAMURE KIKUE AIACYDA.jpg",
        nome: "EM NAKAMURE KIKUE AIACYDA",
        localizacao: "R. Lizeu Odarico Bueno, 695 - Terra Preta",
        telefone: "(11) 4486-2972",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 33,
        foto: "/assets/img/escolas/EM NANCY DE FREITAS ROLIM.jpg",
        nome: "EM NANCY DE FREITAS ROLIM",
        localizacao: "Rua Diamante, 283 - Estancia Santo Antonio",
        telefone: "(11) 4419-2922",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 35,
        foto: "/assets/img/escolas/EM PASCHOAL LUCIANI JUNIOR.jpg",
        nome: "EM PASCHOAL LUCIANI JUNIOR",
        localizacao: "Trav. Satre, S/nº - Jardim Lucia - Terra Preta",
        telefone: "(11) 4486-3232",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 36,
        foto: "/assets/img/escolas/EM PREFEITO SARKIS TELLIAN.jpg",
        nome: "EM PREFEITO SARKIS TELLIAN",
        localizacao: "Av. Charlote Szirmai, 1601 - Capoavinha",
        telefone: "(11) 4419-2455",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 37,
        foto: "/assets/img/escolas/EM PROF ARMANDO PAVANELLI.jpg",
        nome: "EM PROF ARMANDO PAVANELLI",
        localizacao: "Rua Laudemiro Ramos, 858 - Jardim Fernão Dias",
        telefone: "(11) 4419-6352/4419-7744",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 50,
        foto: "/assets/img/escolas/EM PROFA DIOMAR MIRANDA BONI UNIDADE I.jpg",
        nome: "EM PROFA DIOMAR MIRANDA BONI UNIDADE I",
        localizacao: "Arão Sahm, 1180",
        telefone: "(11) 4486-5022",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 38,
        foto: "/assets/img/escolas/EM PROFA DIOMAR MIRANDA BONI UNIDADE 2.jpg",
        nome: "EM PROFA DIOMAR MIRANDA BONI UNIDADE II",
        localizacao: "Rua Antônio de Abreu, 294 - Gibeon - 07662-685",
        telefone: "(11) 4419-6881",
        gestao: "",
        tipo: "Infantil"
      },
      {
        id: 39,
        foto: "/assets/img/escolas/EM PROFESSORA MAGALI GUARISO DE CAMPOS.jpg",
        nome: "EM PROFESSORA MAGALI GUARISO DE CAMPOS",
        localizacao: "Est. Mun. Pref. Sarkis Tellian, KM 05 - Santa Inês",
        telefone: "(11) 4485-2658",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 40,
        foto: "/assets/img/escolas/EM RAMIRA FÉLIX DA SILVA.jpg",
        nome: "EM RAMIRA FÉLIX DA SILVA",
        localizacao: "Av. Antonio de Moraes Neto, S/nº - São Vicente",
        telefone: "(11) 4482-2255",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 41,
        foto: "/assets/img/escolas/EM SHIGERU SASAKI.jpg",
        nome: "EM SHIGERU SASAKI",
        localizacao:
          "Rua Fussaki, 23 - Jd. São Francisco - São Francisco - Terra Preta",
        telefone: "(11) 4486-2221",
        gestao: "",
        tipo: "Infantil"
      },
      {
        id: 42,
        foto: "/assets/img/escolas/EM TAKAMICHI UENOJO.jpg",
        nome: "EM TAKAMICHI UENOJO",
        localizacao: "Rua Anjo Gabriel, 541 - Canjica - Terra Preta",
        telefone: "(11) 99101-9945",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 43,
        foto: "/assets/img/escolas/EM TIRSI ANNA CASTELANI GAMBERINI.jpg",
        nome: "EM TIRSI ANNA CASTELANI GAMBERINI",
        localizacao: "Rua Bom Jesus da Terra Preta, 39 - Terra Preta",
        telefone: "(11) 4486-2220",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 44,
        foto: "/assets/img/escolas/EM VEREADOR RENATO PINHO.jpg",
        nome: "EM VEREADOR RENATO PINHO",
        localizacao: "Al. Dos Pinheiros, 300 - Jardim Cinco Lagos",
        telefone: "(11) 4483-4112",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 45,
        foto: "/assets/img/escolas/EM VOVÓ DANILA.jpg",
        nome: "EM VOVÓ DANILA",
        localizacao: "Rua Primavera, 95 - Parque Nautico",
        telefone: "(11) 4419-3609",
        gestao: "",
        tipo: "Infantil"
      },
      // {
      //   id: 46,
      //   foto: "/assets/img/escolas/CRECHE TIA LAURA 2.jpg",
      //   nome: "CRECHE TIA LAURA",
      //   localizacao: "Benedito Galrão de França, nº 38 - Jd. Galrão",
      //   telefone: "(11) 4419-7283",
      //   gestao: "",
      //   tipo: "Creche",
      // },
      {
        id: 47,
        foto: "/assets/img/escolas/CEMAD FRANCISCO TASSO - TIO CHICO.jpg",
        nome: "CEMAD FRANCISCO TASSO - TIO CHICO",
        localizacao: "Avenida Tabelião Passarela N 850",
        telefone: "(11) 4604-0211",
        gestao: "",
        tipo: "CEMAD"
      },
      {
        id: 48,
        foto: "/assets/img/escolas/EM CRISTIANE SILVA COSTA.jpg",
        nome: "EM CRISTIANE SILVA COSTA",
        localizacao: "Rua Lívia Val Silva André N 478",
        telefone: "(11) 4419-0196",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 49,
        foto: "/assets/img/escolas/EM EDMÉA IGNEZ CHAMMA.jpg",
        nome: "EM EDMÉA IGNEZ CHAMMA",
        localizacao: "Rua da Primavera N 677",
        telefone: "(11) 4604-3868",
        gestao: "",
        tipo: "Fundamental"
      },
      {
        id: 49,
        foto: "/assets/img/escolas/EM BENEDICTO MARCIANO BUENO.jpg",
        nome: "EM BENEDICTO MARCIANO BUENO",
        localizacao: "Estrada Municipal do Mato Dentro, s/n",
        telefone: "(11) 4486-5181",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 49,
        foto: "/assets/img/escolas/EM DIVINA ANASTÁCIO DO NASCIMENTO.jpg",
        nome: "EM DIVINA ANASTÁCIO DO NASCIMENTO",
        localizacao: "Estrada da Lagoa, s/n",
        telefone: "(11) 4486-1944",
        gestao: "",
        tipo: "InfantilFundamental"
      },
      {
        id: 50,
        foto:
          "/assets/img/escolas/CRECHE ESCOLA MUNICIPAL PREFEITO ANTONIO SHIGUEYUKI AIACYDA.jpg",
        nome: "CRECHE ESCOLA MUNICIPAL PREFEITO ANTONIO SHIGUEYUKI AIACYDA",
        localizacao: "RUA JOSÉ MAURÍCIO ROCHA, 149, CENTRO, MAIRIPORÃ - SP",
        telefone: "(11) 4419-2716",
        gestao: "",
        tipo: "Creche"
      },
      {
        id: 51,
        foto:
          "/assets/img/escolas/CRECHE ESCOLA MUNICIPAL ANA LUCIA LOESCH GOUVEIA OLIVEIRA.jpg",
        nome: "CRECHE ESCOLA MUNICIPAL ANA LUCIA LOESCH GOUVEIA OLIVEIRA",
        localizacao: "Rua Sao Jorge, 460 , Terra Preta. Mairiporã, SP",
        telefone: "(11) 4419-2882",
        gestao: "",
        tipo: "Creche"
      }
    ],
    professoresDto: [],
    muraisDto: [],

    //=====NOTÍCIAS=====
    noticiasDto: [],
    eventosDto: []
  };

  escolas: Escola[] = [];
  private apiPhp = environment.apiphp;
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
  };

  constructor(private httpClient: HttpClient) {
    this.escolas$.subscribe(escolas => {
      escolas.forEach(esc => {
        this.escolas[esc.tipo]
          ? this.escolas[esc.tipo].push(esc)
          : (this.escolas[esc.tipo] = [esc]);
      });
    });

    if (this.mock.professoresDto)
      this.colaboradores = this.mock.professoresDto.sort(
        () => 0.5 - Math.random()
      );
    if (this.mock.escolasDto)
      this.escolas$.next(
        this.mock.escolasDto
        // res.escolasDto.map(escola => {
        //   return {
        //     ...escola,
        //     mapa:
        //       "https://www.google.com/maps/embed/v1/place?q=" +
        //       encodeURIComponent(escola.localizacao) +
        //       ",Sete+Barras,SP,Brasil&zoom=16&key=" +
        //       mapaApiKey
        //   };
        // })
      );
    if (this.mock.noticiasDto) this.noticias$.next(this.mock.noticiasDto);
    if (this.mock.eventosDto) this.eventos$.next(this.mock.eventosDto);
    if (this.mock.muraisDto) this.murais$.next(this.mock.muraisDto);
  }

  public fetchColaboradores() {
    this.get<Colaborador[]>("professor").subscribe(res => {
      this.todos_colaboradores = res;
      this._buscar_colaboradores$
        .pipe(
          tap(() => this._loading$.next(true)),
          debounceTime(200),
          switchMap(() => this._buscar_colaboradores()),

          tap(() => this._loading$.next(false))
        )
        .subscribe(result => {
          this._todos_colaboradores$.next(result.colaboradores);
          this._total$.next(result.total);
        });

      this._buscar_colaboradores$.next();
    });
  }

  public fetchMuralSecretaria() {
    return this.httpClient.get<any>(
      this.apiPhp.concat("portal-comunidade/boletins-informativos"),
      this.httpOptions
    );
  }

  public fetchNoticias(pagina = "1") {
    return this.httpClient.get<any>(
      this.apiPhp.concat("portal-comunidade/noticias?page=" + pagina),
      this.httpOptions
    );
  }

  public fetchEventos(pagina = "1") {
    return this.httpClient.get<any>(
      this.apiPhp.concat("portal-comunidade/eventos?page=" + pagina),
      this.httpOptions
    );
  }

  public fetchNoticiasDestaque() {
    return this.httpClient.get<Noticia[]>(
      this.apiPhp.concat("portal-comunidade/noticias-destaque"),
      this.httpOptions
    );
  }

  public fetchEventosDestaque() {
    return this.httpClient.get<Noticia[]>(
      this.apiPhp.concat("portal-comunidade/eventos-destaque"),
      this.httpOptions
    );
  }

  getNoticia(noticiaId: number) {
    return this.httpClient.get<Noticia[]>(
      this.apiPhp.concat("portal-comunidade/noticias/" + noticiaId),
      this.httpOptions
    );
  }

  getEvento(eventoId: number) {
    return this.httpClient.get<any>(
      this.apiPhp.concat("portal-comunidade/eventos/" + eventoId),
      this.httpOptions
    );
  }

  get<Tipo>(
    url: string,
    params: HttpParams | { [param: string]: string | string[] } = null
  ): Observable<Tipo> {
    return this.httpClient.get<Tipo>(API_URL + url, { params });
  }

  // public pagina$ = this.get<RespostaApi>("pagina").subscribe(res => {

  //   // if (this.mock.professoresDto)
  //   //   this.colaboradores = this.mock.professoresDto.sort(() => 0.5 - Math.random());
  //   // if (this.mock.escolasDto)
  //   //   this.escolas$.next(
  //   //     this.mock.escolasDto
  //   //     // res.escolasDto.map(escola => {
  //   //     //   return {
  //   //     //     ...escola,
  //   //     //     mapa:
  //   //     //       "https://www.google.com/maps/embed/v1/place?q=" +
  //   //     //       encodeURIComponent(escola.localizacao) +
  //   //     //       ",Sete+Barras,SP,Brasil&zoom=16&key=" +
  //   //     //       mapaApiKey
  //   //     //   };
  //   //     // })
  //   //   );
  //   // if (this.mock.noticiasDto) this.noticias$.next(this.mock.noticiasDto);
  //   // if (this.mock.eventosDto) this.eventos$.next(this.mock.eventosDto);
  //   // if (this.mock.muraisDto) this.murais$.next(this.mock.muraisDto);
  // });

  public colaboradores: Colaborador[] = [];

  public escolas$: BehaviorSubject<Escola[]> = new BehaviorSubject([]);
  public noticias$: BehaviorSubject<Noticia[]> = new BehaviorSubject([]);
  public eventos$: BehaviorSubject<Noticia[]> = new BehaviorSubject([]);
  public murais$: BehaviorSubject<Noticia[]> = new BehaviorSubject([]);

  public todos_colaboradores: Colaborador[] = [];

  //Colaboradores
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _buscar_colaboradores$ = new Subject<void>();
  private _todos_colaboradores$ = new BehaviorSubject<Colaborador[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _state: State = {
    page: 1,
    pageSize: 12,
    searchTerm: "",
    filtro_escola: ""
  };

  get todos_colaboradores$() {
    return this._todos_colaboradores$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get filtro_escola() {
    return this._state.filtro_escola;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set filtro_escola(filtro_escola: string) {
    this._set({ filtro_escola });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._buscar_colaboradores$.next();
  }

  private _buscar_colaboradores(): Observable<SearchResult> {
    const { pageSize, page, searchTerm, filtro_escola } = this._state;

    let colaboradores = this.todos_colaboradores;

    //filter
    colaboradores = colaboradores.filter(colaborador =>
      matches(colaborador, searchTerm)
    );
    if (filtro_escola != "")
      colaboradores = colaboradores.filter(
        colaborador => colaborador.escola == filtro_escola
      );
    const total = colaboradores.length;

    //paginate
    colaboradores = colaboradores.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ colaboradores, total });
  }
}

<section id="topo" class="wow fadeIn">
  <div class="container">
    <header class="section-header">
      <h3>Nossos Colaboradores</h3>
    </header>
  </div>
</section>

<section id="colaboradores">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="filtro_escola">Filtre por Escola:</label>
          <select
            id="filtro_escola"
            class="form-control"
            name="filtro_escola"
            [(ngModel)]="api.filtro_escola"
          >
            <option value="">Todas</option>
            <optgroup
              *ngFor="let tipo of escolas | keyvalue"
              [label]="tipo.key"
            >
              <option *ngFor="let escola of tipo.value" [value]="escola.nome">
                {{ escola.nome }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="searchTerm">Pesquisar:</label>
          <input
            class="form-control"
            type="text"
            name="searchTerm"
            [(ngModel)]="api.searchTerm"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <span class="ml-3" *ngIf="api.loading$ | async">Carregando...</span>
      <div
        class="col-md-3 col-6 wow fadeIn"
        *ngFor="let colaborador of colaboradores$ | async"
      >
        <div class="member" [class.sem_foto]="!colaborador.foto">
          <img
            [src]="
              colaborador.foto
                ? '//' + colaborador.foto
                : '/assets/img/sem-foto.png'
            "
            class="img-fluid"
            alt="{{ colaborador.nome }}"
          />
          <div class="member-info">
            <div class="member-info-content">
              <h4>{{ colaborador.nome }}</h4>
              <span>{{ colaborador.cargo }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ngb-pagination
      class="d-flex justify-content-center"
      [collectionSize]="total$ | async"
      [(page)]="api.page"
      [maxSize]="5"
      [rotate]="true"
      [ellipses]="false"
      [boundaryLinks]="true"
      [pageSize]="api.pageSize"
    ></ngb-pagination>
  </div>
</section>

<app-home-slider id="home"></app-home-slider>
<main id="main">
  <section id="featured-services">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-md-3 col-6 box text-center"
          style="background-color: #23c5ec"
        >
          <a href="#noticias">
            <i class="ion-ios-paper"></i>
            <h4>Notícias</h4>
          </a>
        </div>

        <div
          class="col-md-3 col-6 box text-center"
          style="background-color: #6fc191"
        >
          <a href="#escolas">
            <i class="ion-university"></i>
            <h4>Escolas</h4>
          </a>
        </div>

        <div
          class="col-md-3 col-6 box box-bg text-center"
          style="background-color: #fdc022"
        >
          <a href="#secretaria">
            <i class="ion-person"></i>
            <h4>Secretaria</h4>
          </a>
        </div>

        <div
          class="col-md-3 col-6 box box-bg text-center"
          style="background-color: #ff8217"
          (click)="scroll('#contato')"
        >
          <a href="#contato">
            <i class="ion-ios-telephone"></i>
            <h4>Contato</h4>
          </a>
        </div>
      </div>
    </div>
  </section>

  <app-fique-por-dentro id="comunicados"> </app-fique-por-dentro>
  <app-instituicao id="instituicao"></app-instituicao>

  <section id="contato" class="section-bg wow fadeInUp">
    <div class="container-fluid">
      <div class="section-header">
        <h3>Contato</h3>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14655.635797385188!2d-46.5903012!3d-23.3190621!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x906b453ab5dab46a!2sSecretaria%20Municipal%20de%20Educa%C3%A7%C3%A3o%20de%20Mairipor%C3%A3!5e0!3m2!1spt-BR!2sbr!4v1631125203499!5m2!1spt-BR!2sbr"
        height="300"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        class="w-100"
      ></iframe>

      <div class="row contato-info">
        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-clock-outline"></i>
          <h3>Atendimento</h3>
          <p>08:00 às 17:00 <br />de Segunda à Sexta-feira</p>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-location-outline"></i>
          <h3>Endereço</h3>
          <address>
            Av. Tab. Passarela, 850 <br />
            Mairiporã, SP, 07600-027
          </address>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-telephone-outline"></i>
          <h3>Telefone</h3>
          <p><a href="tel:(11)4604-0200">(11) 4604-0200</a></p>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-email-outline"></i>
          <h3>Email</h3>
          <p>
            <a href="mailto:educacao@mairipora.sp.gov.br">
              educacao@mairipora.sp.gov.br
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</main>


   <section id="eventos" class="noticias">
      <div class="container">
        <header class="section-header">
          <h3>MURAL DA SECRETÁRIA</h3>
          <p>Boletins Informativos</p>
        </header>

        <app-spinner *ngIf="!murais"></app-spinner>

        <div class="row noticias-cols" *ngIf="murais">
          <ng-container *ngIf="murais?.length > 0; else nenhum_evento">
            <div class="col-md-6 wow fadeInUp" *ngFor="let evento of murais">
              <div class="noticias-col">
                <div class="img">
                  <img
                    [src]="
                      evento.foto
                        ? evento.foto.file
                        : '/assets/img/sem-imagem.png'
                    "
                    [alt]="evento.titulo"
                    class="img-fluid w-100"
                    style="height: 150px; object-fit: cover"
                  />
                  <div class="icon">{{ evento.data | date: "dd/MM" }}</div>
                </div>

                <h2 class="title">{{ evento.titulo }}</h2>
                <div class="conteudo">
                  <div [innerHTML]="evento.conteudo"></div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #nenhum_evento>
            <div class="col-12">
              <p class="text-center">Nenhum evento a ser exibido.</p>
            </div>
          </ng-template>
        </div>

        <!-- <div class="text-center" *ngIf="murais && murais?.length > 0">
          <a routerLink="murais" class="btn btn-sm btn-custom mt-4">
            Mais Boletins
          </a>
        </div> -->
      </div>
    </section>
  
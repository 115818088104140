import { PoliticaAppPortalAlunoComponent } from './paginas/politica-privacidade/politica-app-portal-aluno/politica-app-portal-aluno.component';
import { PoliticaPrivacidadeComponent } from './paginas/politica-privacidade/politica-privacidade.component';
import { ListarEventosComponent } from './paginas/eventos/listar-eventos.component';
import { EventoComponent } from './paginas/eventos/evento.component';
import { ListarNoticiasComponent } from './paginas/noticia/listar-noticias.component';
import { NoticiaComponent } from "./paginas/noticia/noticia.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./paginas/home/home.component";

import { OrganogramaComponent } from "./paginas/organograma/organograma.component";
import { NossosColaboradoresComponent } from "./paginas/nossos-colaboradores/nossos-colaboradores.component";
import { EscolaDetalhesComponent } from "./paginas/escola-detalhes/escola-detalhes.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "politica-de-privacidade", component: PoliticaPrivacidadeComponent},
  { path: "privacidade-portal-aluno-app", component: PoliticaAppPortalAlunoComponent},
  { path: "organograma", component: OrganogramaComponent },
  { path: "colaboradores", component: NossosColaboradoresComponent },
  { path: "noticias", component: ListarNoticiasComponent },
  { path: "eventos", component: ListarEventosComponent },
  { path: "escola/:id", component: EscolaDetalhesComponent },
  { path: "noticia/:id", component: NoticiaComponent },
  { path: "noticias/:id", component: NoticiaComponent },
  { path: "evento/:id", component: EventoComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit, PipeTransform } from "@angular/core";
import { ApiService } from "src/app/_services/api.service";
import { Colaborador } from "src/app/interfaces/colaborador.model";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { RespostaApi } from "src/app/interfaces/resposta.model";

@Component({
  templateUrl: "./nossos-colaboradores.component.html",
  styles: [``]
})
export class NossosColaboradoresComponent implements OnInit {
  colaboradores$: Observable<Colaborador[]>;
  total$: Observable<number>;

  constructor(public api: ApiService) {
    if (api.todos_colaboradores.length == 0) api.fetchColaboradores();
    this.colaboradores$ = api.todos_colaboradores$;
    this.total$ = api.total$;
  }
  escolas = [];
  ngOnInit() {
    this.api.escolas$.subscribe(escolas => {
      escolas.forEach(esc => {
        this.escolas[esc.tipo]
          ? this.escolas[esc.tipo].push(esc)
          : (this.escolas[esc.tipo] = [esc]);
      });
    });
  }
}

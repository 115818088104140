import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { Component, OnInit } from "@angular/core";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-evento",
  templateUrl: "./evento.component.html",
  styles: [
    `
      #topo_eventos {
        background: url("/assets/img/bg-eventos.jpg") fixed;
        background-size: cover;
        background-position: bottom;
        padding: 30vh 0 20vh;
        text-align: left;
      }

      #topo_eventos h3 {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
      }

      :host ::ng-deep .ck-editor__editable_inline {
        border: none;
      }

      :host ::ng-deep .ck.ck-toolbar {
        border: none;
      }
    `,
  ],
})
export class EventoComponent implements OnInit {
  Editor = ClassicEditor;
  evento: any;
  eventoId: number;

  constructor(public api: ApiService, private route: ActivatedRoute) {
    this.eventoId = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.api.getEvento(this.eventoId).subscribe((res) => {
      this.evento = res.data;
    });
  }
}

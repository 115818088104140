import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styles: [
    `
      .float {
        position: fixed;
        width: 55px;
        height: 55px;
        bottom: 65px;
        right: 20px;
        background-color: #25d366;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
      }

      .my-float {
        margin-top: 4px;
      }
    `
  ]
})
export class FooterComponent implements OnInit {
  constructor() {}
  fullDate = new Date();
  year = this.fullDate.getFullYear();
  ngOnInit() {}
}

import { Component, OnInit, Input } from "@angular/core";
import { Noticia } from "src/app/interfaces/noticia.model";
import { Observable } from "rxjs";

@Component({
  selector: "app-secao-eventos",
  template: `
    <section id="eventos" class="noticias">
      <div class="container">
        <header class="section-header">
          <h3>Nossos Eventos</h3>
          <p>Não Perca!</p>
        </header>

        <app-spinner *ngIf="!eventos"></app-spinner>

        <div class="row noticias-cols" *ngIf="eventos">
          <ng-container *ngIf="eventos.length > 0; else nenhum_evento">
            <div class="col-md-6 wow fadeInUp" *ngFor="let evento of eventos">
              <div class="noticias-col" routerLink="/evento/{{ evento.id }}">
                <div class="img">
                  <img
                    [src]="
                      evento.foto
                        ? evento.foto.file
                        : '/assets/img/sem-imagem.png'
                    "
                    [alt]="evento.titulo"
                    class="img-fluid w-100"
                    style="height: 150px; object-fit: cover"
                  />
                  <div class="icon">{{ evento.data | date: "dd/MM" }}</div>
                </div>

                <h2 class="title">{{ evento.titulo }}</h2>
                <div class="conteudo">
                  <div [innerHTML]="evento.conteudo"></div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #nenhum_evento>
            <div class="col-12">
              <p class="text-center">Nenhum evento a ser exibido.</p>
            </div>
          </ng-template>
        </div>

        <div class="text-center" *ngIf="eventos && eventos.length > 0">
          <a routerLink="eventos" class="btn btn-sm btn-custom mt-4">
            Mais Eventos
          </a>
        </div>
      </div>
    </section>
  `,
  styles: [],
})
export class SecaoEventosComponent implements OnInit {
  @Input() eventos: Noticia[];
  constructor() {}

  ngOnInit() {}
}

import { ApiService } from "src/app/_services/api.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-listar-eventos",
  templateUrl: "./listar-eventos.component.html",
  styles: [
    `
      #topo_eventos {
        background: url("/assets/img/bg-eventos.jpg") fixed;
        background-size: cover;
        background-position: bottom;
        padding: 30vh 0 20vh;
        text-align: left;
      }

      #topo_eventos h3 {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
      }
    `,
  ],
})
export class ListarEventosComponent implements OnInit {
  page = 1;
  pageSize = 12;
  collectionSize = null;
  eventos: any[];

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.fetchEventos(1);
  }

  fetchEventos(pagina) {
    this.eventos = null;
    this.apiService.fetchEventos(pagina).subscribe((res) => {
      this.eventos = res.data;
      this.page = res.meta.current_page;
      this.collectionSize = res.meta.total;
    });
  }

  onPaginaAlterada(eventPage) {
    this.fetchEventos(eventPage);
  }
}

<app-secao-noticias [noticias]="noticiasDestaque"></app-secao-noticias>

<section id="facts" class="wow fadeIn">
  <div class="container">
    <div class="row counters">
      <div class="col-lg-3 col-6 text-center">
        <span>{{ n_professores }}</span>
        <p>Professores</p>
      </div>

      <div class="col-lg-3 col-6 text-center">
        <span>{{ n_escolas }}</span>
        <p>Escolas</p>
      </div>

      <div class="col-lg-3 col-6 text-center">
        <span>{{ n_alunos }}</span>
        <p>Alunos</p>
      </div>

      <div class="col-lg-3 col-6 text-center">
        <span>{{ n_projetos }}</span>
        <p>Funcionários Administrativos</p>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <div class="row">
    <app-mural-da-secretaria
    class="col-lg-6"
    [murais]="murais">
</app-mural-da-secretaria>
    <app-secao-eventos
      class="col-lg-6"
      [eventos]="eventosDestaque"
    ></app-secao-eventos>
  </div>
</div>
<section id="editais" class="faixa wow fadeIn">
  <div class="container text-center">
    <a
      class="cta-btn mr-2"
      href="http://www.mairipora.sp.gov.br/"
      target="_blank"
    >
      <i class="ion-android-document"></i> Editais
    </a>
  </div>
</section>

import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
@Component({
  templateUrl: "./home.component.html"
})
export class HomeComponent {
  constructor(router: Router) {
    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector("#" + tree.fragment);
          if (element) {
            element.scrollIntoView(true);
          }
        }
      }
    });
  }

  scroll(el) {
    document.querySelector(el).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    });
  }
}

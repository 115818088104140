import { ApiService } from "src/app/_services/api.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-listar-noticias",
  templateUrl: "./listar-noticias.component.html",
  styleUrls: [],
})
export class ListarNoticiasComponent implements OnInit {
  page = 1;
  pageSize = 12;
  collectionSize;

  noticias: any[];

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.fetchNoticias(1);
  }

  fetchNoticias(pagina) {
    this.noticias = null;
    this.apiService.fetchNoticias(pagina).subscribe((res) => {
      this.noticias = res.data;
      this.page = res.meta.current_page;
      this.collectionSize = res.meta.total;
    });
  }

  onPaginaAlterada(eventPage) {
    this.fetchNoticias(eventPage);
  }
}

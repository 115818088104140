<section id="topo_eventos" class="wow fadeIn">
  <div class="container">
    <header class="section-header">
      <h3>Eventos</h3>
    </header>
  </div>
</section>

<div class="container mt-5 mb-5">
  <div style="min-height: 300px">
    <app-spinner *ngIf="!eventos"></app-spinner>

    <ng-container *ngIf="eventos">
      <p *ngIf="eventos.length == 0">Nenhum evento cadastrado.</p>

      <div class="row" *ngIf="eventos.length > 0">
        <div class="col-md-4" *ngFor="let evento of eventos">
          <div
            class="card mt-4"
            [routerLink]="['/evento', evento.id]"
            style="height: 550px; cursor: pointer"
          >
            <img
              [src]="
                evento.fotoPrincipal
                  ? evento.fotoPrincipal
                  : '/assets/img/sem-imagem.png'
              "
              class="card-img-top img-fluid mt-4"
              style="height: 200px; object-fit: contain"
            />
            <div class="card-body">
              <h6 class="card-title text-center">{{ evento.titulo }}</h6>
              <div
                class="overflow-auto text-justify"
                style="max-height: 150px"
                [innerHTML]="
                  evento.descricao?.length > 200
                    ? (evento.descricao | slice: 0:150) + '...'
                    : evento.descricao
                "
              ></div>
            </div>
            <div class="card-footer">
              <a [routerLink]="['/evento', evento.id]">Ler mais</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <ngb-pagination
          class="mt-2 d-flex justify-content-center"
          *ngIf="pageSize < collectionSize"
          (pageChange)="onPaginaAlterada($event)"
          [collectionSize]="collectionSize"
          [page]="page"
          [maxSize]="10"
          [pageSize]="pageSize"
          [rotate]="true"
          [ellipses]="false"
          [boundaryLinks]="true"
        >
        </ngb-pagination>
      </div>
    </ng-container>
  </div>
</div>

import { Noticia } from "src/app/interfaces/noticia.model";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/_services/api.service";

@Component({
  selector: "app-fique-por-dentro",
  templateUrl: "./fique-por-dentro.component.html",
  styles: [],
})
export class FiquePorDentroComponent implements OnInit {
  noticiasDestaque: Noticia[];
  eventosDestaque: Noticia[];
  murais: Noticia[];

  constructor(public api: ApiService) {}

  n_professores;
  n_escolas;
  n_alunos;
  n_projetos;

  ngOnInit() {
    this.fetchNoticiasDestaque();
    this.fetchEventosDestaque();
    this.fetchMuralSecretaria();

    this.n_professores = 379;
    this.n_escolas = 45;
    this.n_alunos = 8924;
    this.n_projetos = 390;
  }

  fetchNoticiasDestaque() {
    this.api.fetchNoticiasDestaque().subscribe((res) => {
      this.noticiasDestaque = res.sort((a, b) => (a.data < b.data ? 1 : -1));
    });
  }

  fetchEventosDestaque() {
    this.api.fetchEventosDestaque().subscribe((res) => {
      this.eventosDestaque = res.sort((a, b) => (a.data < b.data ? 1 : -1));
    });
  }

  fetchMuralSecretaria() {
    this.api.fetchMuralSecretaria().subscribe((res) => {
      this.murais = res.data.sort((a, b) => (a.data < b.data ? 1 : -1));
    });
  }


  
}

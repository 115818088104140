import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { ListaAnexosComponent } from './shared/lista-anexos/lista-anexos.component';
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";

//Modulos
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { NgwWowModule } from "ngx-wow";

//Layout
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
//Paginas
import { HomeComponent } from "./paginas/home/home.component";
import { OrganogramaComponent } from "./paginas/organograma/organograma.component";

//Secoes
import { HomeSliderComponent } from "./paginas/home/home-slider/home-slider.component";

import { InstituicaoComponent } from "./paginas/home/instituicao/instituicao.component";
import { MuralDaSecretariaComponent } from "./paginas/home/fique-por-dentro/mural-da-secretaria.component";
import { SecaoColaboradoresComponent } from "./paginas/home/instituicao/secao-colaboradores.component";
import { SecaoEscolasComponent } from "./paginas/home/instituicao/secao-escolas.component";

import { SecaoNoticiasComponent } from "./paginas/home/fique-por-dentro/secao-noticias.component";
import { SecaoEventosComponent } from "./paginas/home/fique-por-dentro/secao-eventos.component";
import { FiquePorDentroComponent } from "./paginas/home/fique-por-dentro/fique-por-dentro.component";
import { ScrollSpyDirective } from "./directive/scroll-spy.directive";
import { NossosColaboradoresComponent } from "./paginas/nossos-colaboradores/nossos-colaboradores.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { QuadroInfoComponent } from "./paginas/home/fique-por-dentro/quadro-info.component";
import { EscolaDetalhesComponent } from "./paginas/escola-detalhes/escola-detalhes.component";

import { ModalInfoComponent } from "./paginas/home/fique-por-dentro/modal-info.component";
import { NoticiaComponent } from "./paginas/noticia/noticia.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";

import { ListarNoticiasComponent } from './paginas/noticia/listar-noticias.component';
import { DownloadAnexoComponent } from './shared/download-anexo/download-anexo.component';
import { EventoComponent } from './paginas/eventos/evento.component';
import { ListarEventosComponent } from './paginas/eventos/listar-eventos.component';
import { ListaEscolasComponent } from './shared/lista-escolas/lista-escolas.component';
import { MatchHeightDirective } from './shared/directives/match-height.directive';
import { PoliticaPrivacidadeComponent } from './paginas/politica-privacidade/politica-privacidade.component';
import { PoliticaAppPortalAlunoComponent } from './paginas/politica-privacidade/politica-app-portal-aluno/politica-app-portal-aluno.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,

    MuralDaSecretariaComponent,
    HomeSliderComponent,

    SecaoColaboradoresComponent,
    SecaoEscolasComponent,
    SecaoNoticiasComponent,
    SecaoEventosComponent,

    OrganogramaComponent,

    FooterComponent,

    FiquePorDentroComponent,

    InstituicaoComponent,
    ScrollSpyDirective,
    NossosColaboradoresComponent,
    QuadroInfoComponent,
    EscolaDetalhesComponent,
    ModalInfoComponent,
    NoticiaComponent,
    SpinnerComponent,
    ListarNoticiasComponent,
    DownloadAnexoComponent,
    ListaAnexosComponent,
    EventoComponent,
    ListarEventosComponent,
    ListaEscolasComponent,
    MatchHeightDirective,
    PoliticaPrivacidadeComponent,
    PoliticaAppPortalAlunoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule, //Bootstrap
    NgwWowModule,
    CKEditorModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [ModalInfoComponent],
})
export class AppModule {}

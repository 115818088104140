import { Component, OnInit, Input } from "@angular/core";
import { Noticia } from "src/app/interfaces/noticia.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./modal-info.component.html",
  styles: []
})
export class ModalInfoComponent implements OnInit {
  @Input() info: Noticia;
  constructor(public modal: NgbActiveModal) {}
  regex = /((https:|http:|www\.)\S*)/;
  conteudo = "";
  ngOnInit() {
    this.conteudo = this.info.conteudo.replace(
      this.regex,
      '<a href="$1" target="_blank">$1</a>'
    );
  }
}

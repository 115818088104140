import { Component, OnInit } from "@angular/core";

@Component({
  templateUrl: "./organograma.component.html",
  styles: [
    `
      h4 {
        padding-top: 20px;
        font-size: 1.1rem !important;
        font-weight: 600;
      }
      p {
        text-align: justify;
      }
    `
  ]
})
export class OrganogramaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

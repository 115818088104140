import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instituicao',
  templateUrl: './instituicao.component.html',
  styles: []
})
export class InstituicaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, AfterViewInit } from "@angular/core";

import { NgwWowService } from "ngx-wow";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements AfterViewInit {
  constructor(private wowService: NgwWowService) {}
  ngAfterViewInit() {
    this.wowService.init();
  }
}

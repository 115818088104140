import { Component, OnInit, Input } from "@angular/core";

import { Noticia } from "src/app/interfaces/noticia.model";
import { Observable } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalInfoComponent } from "./modal-info.component";

@Component({
  selector: "app-secao-noticias",
  template: `
    <section id="noticias" class="noticias">
      <div class="container">
        <header class="section-header">
          <h3>Notícias</h3>
          <p>Notícias em destaque</p>
        </header>

        <app-spinner *ngIf="!noticias"></app-spinner>

        <div class="row noticias-cols" *ngIf="noticias && noticias.length > 0">
          <div class="col-md-3 wow fadeInUp" *ngFor="let noticia of noticias">
            <div class="noticias-col" routerLink="/noticias/{{ noticia.id }}">
  <div>
              <div class="img">
                <img
                  [src]="
                    noticia.foto
                      ? noticia.foto.file
                      : '/assets/img/sem-imagem.png'
                  "
                  [alt]="noticia.titulo"
                  class="img-fluid w-100"
                />
                <div class="icon">{{ noticia.data | date: "dd/MM" }}</div>
              </div>

              <h2 class="title">{{ noticia.titulo }}</h2>
              <div class="conteudo">
                <div [innerHTML]="noticia.conteudo"></div>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center" *ngIf="noticias && noticias.length > 0">
          <a routerLink="noticias" class="btn btn-custom mt-4">Mais Notícias</a>
        </div>

        <p *ngIf="noticias && noticias.length == 0">
          Nenhuma notícia a ser exibida.
        </p>
      </div>
    </section>
  `,
  styles: [`
      .title {
        display: table;
      }
    `],

})
export class SecaoNoticiasComponent implements OnInit {
  @Input() noticias: Noticia[];
  constructor(private modalService: NgbModal) { }

  ngOnInit() { }
}

import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { filetypeIcone } from "../functions/filetype-icone";
import * as FileSaver from 'file-saver';

@Component({
  selector: "app-lista-anexos",
  templateUrl: "./lista-anexos.component.html",
})
export class ListaAnexosComponent implements OnInit {
  @Input() anexos = null;
  @Input() size: "lg" | "sm" | "md" = "md";
  @Input() url_anexo = "api/atividadeanexo?anexoId=";
  @Input() direto = false;

  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}
  filetypeIcone = filetypeIcone;

  ngOnInit() {}

  downloadLink(conteudo) {
    return environment.apiphp.concat(this.url_anexo + conteudo.id);
  }
  downloadUrl(conteudo) {
    this.http
      .get<any>(environment.api.concat(this.url_anexo + conteudo.id), {
        responseType: "json",
      })
      .subscribe(
        (res) => {
          fetch(res.file).then((res) => res.blob());

          if (res.file.length < 6) {
            FileSaver.saveAs(new Blob([""]), res.filename);
            return;
          }

          FileSaver.saveAs(res.file, res.filename, { autoBom: true });
        },
        (err) => console.error("ERRO", err)
      );
  }

  sanitize(file) {
    return this.domSanitizer.bypassSecurityTrustUrl(file);
  }
  preloadAudio(conteudo) {
    conteudo.audio = "loading";
    return this.http
      .get(environment.apiphp.concat(this.url_anexo + conteudo.id), {
        responseType: "blob",
      })
      .subscribe((res) => {
        conteudo.audio = URL.createObjectURL(res);
      });
  }
}

<div class="text-center">
  <p class="m-0">
    <small>Carregando</small>
  </p>

  <div class="spinner-grow text-success" role="status"></div>
  <div class="spinner-grow text-warning" role="status"></div>
  <div class="spinner-grow text-danger" role="status"></div>
  <div class="spinner-grow text-primary" role="status"></div>
</div>

import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[matchHeight]",
})
export class MatchHeightDirective {
  @Input()
  matchHeight: any;

  @Input() size: "xs" | "sm" | "md" | "lg" | "xl" = "xs";
  minWidth = 0;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    switch (this.size) {
      case "xl":
        this.minWidth = 1200;
        break;
      case "lg":
        this.minWidth = 992;
        break;
      case "md":
        this.minWidth = 768;
        break;
      case "sm":
        this.minWidth = 576;
        break;
      case "xs":
      default:
        this.minWidth = 0;
    }
  }
  ngAfterViewChecked() {
    // call our matchHeight function here later
    this.matchHeights(this.el.nativeElement, this.matchHeight);
  }

  @HostListener("window:resize")
  onResize() {
    // call our matchHeight function here later
    this.matchHeights(this.el.nativeElement, this.matchHeight);
  }

  matchHeights(parent: HTMLElement, className: string) {
    // match height logic here
    const windowSize = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    if (!parent) {
      return;
    }
    const children = parent.getElementsByClassName(className);

    if (!children) {
      return;
    }

    // reset all children height
    Array.from(children).forEach((x: HTMLElement) => {
      x.style.height = "initial";
    });

    // gather all height
    const itemHeights = Array.from(children).map(
      (x) => x.getBoundingClientRect().height
    );

    // find max height
    const maxHeight = itemHeights.reduce(
      (prev, curr) => (curr > prev ? curr : prev),
      0
    );

    Array.from(children).forEach(
      (x: HTMLElement) =>
        (x.style.height = windowSize > this.minWidth ? `${maxHeight}px` : null)
    );
  }
}

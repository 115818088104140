import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-politica-app-portal-aluno",
  templateUrl: "./politica-app-portal-aluno.component.html",
  styles: [
    `
      #topo_politica {
        background: url("/assets/img/escola/slider1.jpg") fixed;
        background-size: cover;
        background-position: bottom;
        padding: 30vh 0 20vh;
        text-align: left;
      }

      #topo_politica h3 {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
      }
    `,
  ],
})
export class PoliticaAppPortalAlunoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

<footer id="footer">
  <div class="container">
    <div class="copyright">
      Copyright &copy; {{year}}. Todos os direitos reservados
    </div>
    <div class="credits">
      Secretaria Municipal de Educação
    </div>
  </div>
</footer>
<a
  href="https://api.whatsapp.com/send?phone=5511999076408&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20o%20portal%20da%20comunidade."
  class="float"
  target="_blank"
>
  <div class="my-float">
    <i class="ion-social-whatsapp"></i>
  </div>
</a>
<a href="#" routerLink="/" fragment="home" id="back-to-top">
  <i class="ion-android-arrow-up"></i>
</a>
<!-- Global site tag (gtag.js) - Google Analytics -->
<script src="https://www.googletagmanager.com/gtag/js?id=UA-124906078-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "UA-124906078-1");
</script>

<app-lista-escolas></app-lista-escolas>

<section id="organograma" class="faixa wow fadeIn">
  <div class="container text-center">
    <a class="cta-btn mr-2" routerLink="/organograma">
      <i class="ion-android-people"></i> Organograma
    </a>
  </div>
</section>

<section id="secretaria" class="section-bg wow fadeIn">
  <div class="container">
    <header class="section-header">
      <h3 class="pt-5 section-title">Mensagem da Secretária</h3>
    </header>
    <div class="row">
      <div class="col-md-7">
        <p class="text-justify">
          Possuindo larga formação e experiência na área de educação,<br>
          Lilian é formada pelo CEFAM (Centro Específico de Formação e Aperfeiçoamento do Magistério),<br>
          além de também Geografa, Pedagoga e pós-graduada em Psicologia Educacional.<br>
          Iniciou sua carreira como professora de ensino infantil e fundamental na rede municipal de Itapevi,<br>
          onde também atuou como professora na rede estadual do ensino médio,<br>
          coordenadora pedagógica, diretora de escola, supervisora de ensino e culminando como Secretária de Educação de
          Itapevi.<br>
          Posteriormente foi também professora e tutora na Universidade Uniararas. No município de Carapicuíba continuou
          sua carreira como diretora pedagógica,<br>
          Secretária de Educação e Secretário Adjunta de Administração.<br>
          <span class="text-info font-weight-bold">LILIAN BRAGA VIEIRA</span>
          <br />
          <span class="font-weight-bold">Secretária de Educação</span>
        </p>
      </div>
      <div class="col-md-5">
        <img src="/assets/img/lilian.jpg" class="img-thumbnail" />
      </div>
    </div>
  </div>
</section>

<section id="services">
  <div class="container">
    <header class="section-header wow fadeInUp">
      <h3>Nossos Pilares</h3>
      <h4>A finalidade da Secretaria da Educação</h4>
      <p class="text-justify">
        A Secretaria de Educação de Mairiporã tem a atribuição de planejar e
        executar as ações educacionais no âmbito do Sistema Municipal de Ensino,
        por meio da promoção de políticas públicas de democratização, acesso,
        permanência e sucesso do aluno ao ensino público gratuito e de qualidade
        nos níveis da Educação Infantil, Ensino Fundamental, nas modalidades de
        Educação Especial e Educação de Jovens e Adultos.
      </p>
    </header>

    <div class="row">
      <div class="col-lg-4 box wow bounceInUp">
        <div class="icon"><i class="ion-bookmark"></i></div>
        <h4 class="title">Valores</h4>
        <p class="description text-justify">
          • Ética; <br />
          • Transparência;<br />
          • Efetividade; <br />
          • Afetividade; <br />
          • Equidade; <br />
          • Respeito; <br />
          • Justiça; <br />
          • Busca de excelência; <br />
          • Disposição de servir.
        </p>
      </div>
      <div class="col-lg-4 box wow bounceInUp">
        <div class="icon"><i class="ion-university"></i></div>
        <h4 class="title">Missão</h4>
        <p class="description text-justify">
          Tem como objetivos centrais preparar os educandos para o exercício da
          cidadania; valorizar os profissionais da educação; garantir; promover
          a integração entre a escola, a família e a comunidade; contribuir para
          ampliação do acesso, garantia de permanência e sucesso das crianças
          com idade escolar obrigatória e melhorias dos resultados da
          aprendizagem em todos os níveis de ensino.
        </p>
      </div>

      <div class="col-lg-4 box wow bounceInUp">
        <div class="icon"><i class="ion-eye"></i></div>
        <h4 class="title">Visão</h4>
        <p class="description text-justify">
          Ser uma gestão que busca qualidade e excelência dos serviços
          educacionais, transparências e compromisso por ações, visando à
          formação cidadã do aluno.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="topo" class="wow fadeIn">
  <div class="container">
    <header class="section-header">
      <h3>Organograma</h3>
    </header>
  </div>
</section>

<div class="container text-center mb-5">
  <img src="/assets/img/organograma.png" alt="" class="img-fluid mt-4" />
</div>

<div class="container">
  <div class="row">
    <div class="col-md-6">
      <h4 style="color: #21a117;">
        Secretaria Municipal de Educação e Cultura
      </h4>

      <h4 style="color: #000;">
        Secretaria da Educação
      </h4>

      <h4 style="color: #000;">
        Diretora da Educação
      </h4>

      <h4 style="color: #ff7302;">
        Setor de Atendimento ao público
      </h4>
      <p>Atende ao público para melhor orienta-los.</p>

      <h4 style="color: #ff7302;">Depósito Municipal</h4>

      <h4 style="color: #ff7302;">
        Coordenação Pedagógica Infantil:
      </h4>
      <p>
        Os responsáveis pela efetivação do processo do ensino aprendizagem nos
        espaços educativos são os gestores educacionais, no qual o professor é o
        gestor em sala de aula. Dessa forma a construção do conhecimento
        acontece com a união da coordenação pedagógica, direção escolar e
        educadores.
      </p>

      <h4 style="color: #ff7302;">Coordenação Pedagógica Fundamental:</h4>
      <p>
        Para instaurar na escola o significado do trabalho coletivo, é
        fundamental que o coordenador pedagógico possibilite ações de parceria,
        de modo que, “movidas por necessidades semelhantes, (as pessoas) se
        implicam no desenvolvimento de ações para atingir objetivos e metas
        comuns.
      </p>
      <h4 style="color: #ff7302;">Transporte Escolar</h4>

      <h4 style="color: #ff7302;">Seção Merenda:</h4>
      <p>Técnicas de preparos de alimentos</p>

      <h4 style="color: #ff7302;">Brinquedoteca:</h4>
      <p>
        A brinquedoteca é um espaço preparado para estimular a criança a
        brincar, possibilitando o acesso a uma grande variedade de brinquedos.
      </p>
    </div>

    <!-- --------------------------- COLUNA ----------------------------------------- -->
    <div class="col-md-6">
      <h4 style="color: #1d78cb;">Diretor de Creche:</h4>
      <p>
        O papel do diretor. Ter uma visão global da instituição com foco na
        aprendizagem dos alunos é um dos principais atributos de quem assume a
        direção de uma escola. O ideal é que cada creche e pré-escola tenha uma
        pessoa presente que olhe e responda por tudo o que lá acontece.
      </p>
      <h4 style="color: #1d78cb;">Diretoras Infantil</h4>
      <h4 style="color: #1d78cb;">Coordenador de Creche:</h4>
      <p>
        Elaborar em conjunto com a equipe técnica, o planejamento das atividades
        a serem desenvolvidas junto aos professores e crianças. Orientar e
        capacitar funcionários com base em programas pré-estabelecidos.
        Gerenciar e acompanhar a elaboração da proposta pedagógica e avaliar as
        atividades educacionais.
      </p>

      <h4 style="color: #1d78cb;">Diretoras do Fundamental</h4>

      <h4 style="color: #1d78cb;">Unidades Escolares:</h4>
      <p>
        Instituição concebida para o ensino de alunos sob a direção de
        professores.
      </p>

      <h4 style="color: #a675af;">
        Coordenação Escolar Pedagógica Infantil:
      </h4>

      <p>
        Coordenação pedagógica. A Coordenação pedagógica é um cargo da área da
        educação que tem como objetivo melhorar as práticas dos professores na
        formação continuada da escola. No Brasil,na década de 1920 que surgiu a
        função de coordenador pedagógico.rmado
      </p>

      <h4 style="color: #a675af;">
        Coordenação Escolar Pedagógica Fundamental:
      </h4>
      <p>
        Conhecimento das teorias de Educação Infantil e dos processos de
        desenvolvimento e aprendizagem da criança. - Clareza de que educar e
        cuidar são indissociáveis nesse segmento. - Domínio das políticas
        públicas para a área e da legislação específica. - Conhecimento
        atualizado da produção acadêmica e das pesquisas sobre Educação
        Infantil. - Facilidade para se relacionar com pessoas e liderança.
      </p>
    </div>
  </div>
</div>

import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/_services/api.service";
import { Component, OnInit } from "@angular/core";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-noticia",
  templateUrl: "./noticia.component.html",
  styles: [
    `
      :host ::ng-deep .ck-editor__editable_inline {
        border: none;
      }

      :host ::ng-deep .ck.ck-toolbar {
        border: none;
      }
    `,
  ],
})
export class NoticiaComponent implements OnInit {
  Editor = ClassicEditor;
  noticia: any;
  noticiaId: number;

  constructor(public api: ApiService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.noticiaId = this.route.snapshot.params.id;

    this.api.getNoticia(this.noticiaId).subscribe((res) => {
      this.noticia = res;
    });
  }
}

<div
  *ngFor="let anexo of anexos"
  class="btn-group mr-2"
  [class.btn-group-lg]="size == 'lg'"
  [class.btn-group-sm]="size == 'sm'"
  role="group"
>
  <ng-container *ngIf="anexo.filetype.includes('audio')">
    <button
      *ngIf="!anexo.audio"
      type="button"
      class="btn btn-warning btn-raised"
      (click)="preloadAudio(anexo)"
    >
      <i class="mr-1 fa fa-play"></i>
    </button>
    <ng-container *ngIf="anexo.audio">
      <ng-container *ngIf="anexo.audio != 'loading'">
        <audio #audioOption>
          <source [src]="sanitize(anexo.audio)" [type]="anexo.filetype" />
        </audio>
        <button
          type="button"
          class="btn btn-success btn-raised"
          (click)="audioOption.play()"
          *ngIf="audioOption.paused"
        >
          <i class="mr-1 fa fa-play"></i>
        </button>
        <button
          type="button"
          class="btn btn-success btn-raised"
          (click)="audioOption.pause()"
          *ngIf="!audioOption.paused"
        >
          <i class="mr-1 fa fa-pause"></i>
        </button>
      </ng-container>
      <button
        type="button"
        class="btn btn-warning btn-raised"
        *ngIf="anexo.audio == 'loading'"
      >
        <i class="mr-1 fa fa-spinner fa-spin"></i>
      </button>
    </ng-container>
  </ng-container>
  <a
    *ngIf="!direto"
    class="btn btn-info btn-raised text-break text-truncate text-left"
    style="width: 150px"
    [title]="anexo.filename"
    (click)="downloadUrl(anexo)"
  >
    <i [className]="'mr-1 fa ' + filetypeIcone(anexo.filetype)"></i>
    <small>{{ anexo.filename }}</small>
  </a>
  <a
    *ngIf="direto"
    class="btn btn-success btn-raised text-break text-truncate text-left"
    style="width: 200px"
    [title]="anexo.filename"
    [href]="downloadLink(anexo)"
    target="_blank"
  >
    <i [className]="'mr-1 fa ' + filetypeIcone(anexo.filetype)"></i>
    <small>{{ anexo.filename }}</small>
  </a>
</div>

<section id="escolas" class="section-bg">
  <div class="container">
    <header class="section-header">
      <h3 class="section-title">Nossas Escolas</h3>
    </header>

    <div class="row">
      <div class="col-lg-12">
        <ul id="escolas-flters">
          <li
            [class.filter-active]="filtro_escolas == 'Creche'"
            (click)="filtro_escolas = 'Creche'"
          >
            Creche
          </li>
          <li
            [class.filter-active]="filtro_escolas == 'CrecheInfantil'"
            (click)="filtro_escolas = 'CrecheInfantil'"
          >
            Creche e Educação Infantil
          </li>
          <li
            [class.filter-active]="filtro_escolas == 'Infantil'"
            (click)="filtro_escolas = 'Infantil'"
          >
            Educação Infantil
          </li>
          <li
            [class.filter-active]="filtro_escolas == 'InfantilFundamental'"
            (click)="filtro_escolas = 'InfantilFundamental'"
          >
            Educação Infantil e Ensino Fundamental
          </li>
          <li
            [class.filter-active]="filtro_escolas == 'Fundamental'"
            (click)="filtro_escolas = 'Fundamental'"
          >
            Ensino Fundamental
          </li>
          <li
            [class.filter-active]="filtro_escolas == 'CEMAD'"
            (click)="filtro_escolas = 'CEMAD'"
          >
            CEMAD
          </li>
        </ul>
      </div>
    </div>

    <div class="row" matchHeight="card">
      <ng-container *ngFor="let escola of api.escolas$ | async">
        <div
          class="col-lg-3 col-md-4 col-sm-6"
          *ngIf="!filtro_escolas || filtro_escolas == escola.tipo"
          data-wow-duration="2s"
        >
          <div class="card custom-card mt-4">
            <img
              *ngIf="!escola.foto"
              [src]="'/assets/img/sem-imagem.png'"
              class="img-fluid"
              alt=""
            />

            <a
              *ngIf="escola.foto"
              href="{{ escola.foto }}"
              data-lightbox="escolas"
              data-title="{{ escola.nome }}"
              title="Ver Foto"
              class="teste"
            >
              <img [src]="escola.foto" class="card-img-top teste" />
            </a>
            <div class="card-body pt-4 pr-2 pl-2 text-center overflow-auto">
              <h6 class="font-weight-bold black">{{ escola.nome }}</h6>
              <p
                class="card-text text-custom"
                *ngIf="escola.localizacao || escola.telefone"
              >
                <ng-container *ngIf="escola.localizacao">
                  <strong>Endereço:</strong> {{ escola.localizacao }}
                </ng-container>
                <br />
                <ng-container *ngIf="escola.telefone">
                  <strong>Telefone:</strong> {{ escola.telefone }}
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<header id="header">
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-light shadow-sm p-3"
    style="background-color: white"
  >
    <a class="navbar-brand ml-xl-5" href="#">
      <img
        style="height: 50px"
        class="img-fluid"
        src="/assets/img/prefeitura-mairipora.png"
        alt="Secretaria Municipal de Educação"
        title="Secretaria Municipal de Educação"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto">
        <!--logos somente no menu mobile-->
        <li class="nav-item d-xl-none d-lg-none mt-4 mb-4">
          <a href="#"
            ><img
              style="height: 40px"
              class="img-fluid"
              src="/assets/img/educasim.png"
              alt="Secretaria Municipal de Educação"
              title="Secretaria Municipal de Educação"
            />
            <!-- <img
              style="height: 40px"
              class="img-fluid"
              src="/assets/img/educamais.png"
              alt="Secretaria Municipal de Educação"
              title="Secretaria Municipal de Educação"
            /> -->
          </a>
        </li>

        <li class="nav-item mr-2 ml-2 mt-2 mt-lg-0">
          <a href="/#home" routerLink="/" fragment="home">
            <button
              type="button"
              class="btn btn-verde btn-round"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              HOME
            </button>
          </a>
        </li>
        <li class="nav-item mr-2 ml-2 mt-2 mt-lg-0">
          <a href="/#comunicados" routerLink="/" fragment="comunicados">
            <button
              type="button"
              class="btn btn-verde btn-round"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              FIQUE POR DENTRO
            </button>
          </a>
        </li>
        <li class="nav-item mr-2 ml-2 mt-2 mt-lg-0">
          <a href="/#instituicao" routerLink="/" fragment="instituicao">
            <button
              type="button"
              class="btn btn-verde btn-round"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              INSTITUIÇÃO
            </button>
          </a>
        </li>
        <li class="nav-item mr-2 ml-2 mt-2 mt-lg-0">
          <a href="/#contato" routerLink="/" fragment="contato">
            <button
              type="button"
              class="btn btn-verde btn-round"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              CONTATO
            </button>
          </a>
        </li>
        <li class="nav-item mr-2 ml-2 mt-2 mt-lg-0">
          <a href="http://professor.mairiporaeducasim.com.br" target="_blank">
            <button
              type="button"
              class="btn btn-vermelho btn-round"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              GESTÃO EDUCACIONAL
            </button>
          </a>
        </li>
        <li class="nav-item mr-2 ml-2 mt-2 mt-lg-0">
          <a href="https://alunos.mairiporaeducasim.com.br" target="_blank">
            <button
              type="button"
              class="btn btn-azul btn-round"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              PORTAL DO ALUNO
            </button>
          </a>
        </li>
      </ul>
      <!--logos somente no menu desktop-->
      <div class="d-flex">
        <div class="dropdown d-none d-xl-block d-lg-block">
          <ul class="nav-menu mr-xl-5">
            <li>
              <a class="dropdown-item" href="#"
                ><img
                  style="height: 40px"
                  class="img-fluid"
                  src="/assets/img/educasim.png"
                  alt="Secretaria Municipal de Educação"
                  title="Secretaria Municipal de Educação"
                />
                <!-- <img
                  style="height: 40px"
                  class="img-fluid"
                  src="/assets/img/educamais.png"
                  alt="Secretaria Municipal de Educação"
                  title="Secretaria Municipal de Educação"
                /> -->
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>

<!-- <header id="header" class="shadow-sm">
  <div class="container-fluid">
    <div id="logo" class="pull-left">
      <a routerLink="/">
        <img
          style="height: 40px;"
          class="img-fluid"
          src="/assets/img/prefeitura-mairipora.png"
          alt="Secretaria Municipal de Educação"
          title="Secretaria Municipal de Educação"
        />

        <img
          style="height: 40px;"
          class="img-fluid"
          src="/assets/img/educasim.png"
          alt="Secretaria Municipal de Educação"
          title="Secretaria Municipal de Educação"
        />
      </a>

    </div>

    <div class="justify-content-center">
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li>
            <a href="/#home" routerLink="/" fragment="home">
              <button type="button" class="btn btn-verde btn-round">HOME</button>
            </a>
          </li>
          <li>
            <a href="/#comunicados" routerLink="/" fragment="comunicados">
              <button type="button" class="btn btn-verde btn-round">
                FIQUE POR DENTRO
              </button>
            </a>
          </li>
          <li>
            <a href="/#instituicao" routerLink="/" fragment="instituicao">
              <button type="button" class="btn btn-verde btn-round">
                INSTITUIÇÃO
              </button>
            </a>
          </li>
          <li>
            <a href="/#contato" routerLink="/" fragment="contato">
              <button type="button" class="btn btn-verde btn-round">
                CONTATO
              </button>
            </a>
          </li>
          <li>
            <a href="http://professor.mairiporaeducasim.com.br" target="_blank">
              <button type="button" class="btn btn-vermelho btn-round">
                GESTÃO EDUCACIONAL
              </button>
            </a>
          </li>
          <li>
            <a href="https://alunos.mairiporaeducasim.com.br" target="_blank">
              <button type="button" class="btn btn-azul btn-round">
                PORTAL DO ALUNO
              </button>
            </a>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</header> -->

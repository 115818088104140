<div *ngIf="escola">
  <section>
    <div
      class="container-fluid foto"
      [ngStyle]="{
        'background-image': 'url(//' + escola.foto + ')'
      }"
    ></div>
  </section>
  <section id="contato" class="section-bg wow fadeInUp">
    <div class="container-fluid">
      <!-- <iframe
      *ngIf="escola$ | async as escola"
      [src]="escola.mapa"
      height="300"
      frameborder="0"
      style="border:0;"
      allowfullscreen=""
      class="w-100"
    >
    </iframe> -->

      <div class="section-header wow fadeInUp">
        <h3>{{ escola.nome }}</h3>
        <p>
          Gestão: <b>{{ escola.gestao }}</b>
        </p>
      </div>

      <div class="row contato-info">
        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-clock-outline"></i>
          <h3>Atendimento</h3>
          <p>{{ escola.horario }}</p>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-location-outline"></i>
          <h3>Endereço</h3>
          <address>{{ escola.localizacao }}</address>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-telephone-outline"></i>
          <h3>Telefone</h3>
          <p>{{ escola.telefone }}</p>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <i class="ion-ios-email-outline"></i>
          <h3>Email</h3>
          <p>
            <a href="mailto:{{ escola.email }}">
              {{ escola.email }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="faixa wow fadeIn">
    <div class="container text-center">
      <a
        *ngIf="escola.documento"
        class="cta-btn mr-2"
        href="//{{ escola.documento }}"
      >
        <i class="ion-android-document"></i> Documento
      </a>
    </div>
  </section>

  <section id="historia" class="section-bg" *ngIf="escola.historia">
    <div class="container">
      <div class="section-header wow fadeInUp">
        <h3>História</h3>
        <p class="text-justify">{{ escola.historia }}</p>
      </div>
    </div>
  </section>
  
  <section id="colaboradores" *ngIf="(total$ | async) > 0">
    <div class="container">
      <div class="section-header wow fadeInUp">
        <h3>COLABORADORES</h3>
      </div>
      <div class="row">
        <span class="ml-3" *ngIf="api.loading$ | async">Carregando...</span>
        <div
          class="col-md-3 col-6 wow fadeIn"
          *ngFor="let colaborador of colaboradores$ | async"
        >
          <div class="member" [class.sem_foto]="!colaborador.foto">
            <img
              [src]="
                colaborador.foto
                  ? '//' + colaborador.foto
                  : '/assets/img/sem-foto.png'
              "
              class="img-fluid"
              alt="{{ colaborador.nome }}"
            />
            <div class="member-info">
              <div class="member-info-content">
                <h4>{{ colaborador.nome }}</h4>
                <span>{{ colaborador.cargo }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ngb-pagination
        *ngIf="(total$ | async) > api.pageSize"
        class="d-flex justify-content-center"
        [collectionSize]="total$ | async"
        [(page)]="api.page"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
        [pageSize]="api.pageSize"
      ></ngb-pagination>
    </div>
  </section>
</div>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { ApiService } from "src/app/_services/api.service";
import { Observable } from "rxjs";
import { Escola } from "src/app/interfaces/escola.model";
import { Colaborador } from "src/app/interfaces/colaborador.model";

@Component({
  templateUrl: "./escola-detalhes.component.html",
  styles: [
    `
      .foto {
        height: 80vh;
        background: url("/assets/img/slider1.jpg") no-repeat top center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    `
  ]
})
export class EscolaDetalhesComponent implements OnInit {
  colaboradores$: Observable<Colaborador[]>;
  total$: Observable<number>;
  escola: Escola;

  constructor(private route: ActivatedRoute, public api: ApiService) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.api
        .get<Escola>("escola", { id: params.get("id") })
        .subscribe(esc => {
          this.escola = esc;
          this.api.filtro_escola = esc.nome;
          this.colaboradores$ = this.api.todos_colaboradores$;
          this.total$ = this.api.total$;
        });
    });
  }
}
